import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { useRouteMatch } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { grey, green } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { computeQuizMeta, getAssetTypeIcon, isValidQuizMeta } from '../../Services/CourseHelper';
import { muiTheme } from '../../Themes';
import { isClientSide } from '../../Config/ServerConfig';
import CoursesActions from '../../Redux/CoursesRedux';
import NoMatch404 from '../NoMatch404';
import NoMatch401 from '../NoMatch401';
import ChapterQuiz from '../../Components/Shared/Courses/ChapterQuiz';

const ReactHlsPlayer = loadable(() => import('react-hls-player'), { ssr: false });

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '40px',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  sideContainer: {
    padding: 24,
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingBottom: theme.spacing(3),
    },
  },
  card: {
    padding: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
    },
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(-1),
  },
  assetContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(5),
  },
  iframe: {
    width: '100%',
    height: 350,
    borderStyle: 'none',
    '&>*': {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      height: 160,
    },
  },
  btn: {
    backgroundColor: muiTheme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  btnContainer: {
    marginTop: theme.spacing(5),
  },
  unitTitle: {
    padding: theme.spacing(3, 2),
  },
  textContent: {
    marginTop: theme.spacing(5),
  },
  listItemText: {
    '&>*': {
      fontWeight: 800,
      color: theme.palette.text.primary,
    },
  },
  attachmentCard: {
    backgroundColor: muiTheme.current.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  download: {
    color: 'white',
    marginRight: 8,
  },
}));

const ShowChapter = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const match = useRouteMatch();
  const { course_slug: courseSlug, chapter_id: chapterId } = match.params;

  const chapter = useSelector(state => state.courses.chapter);
  const isFetching = useSelector(state => state.courses.isFetching.getCourseChapter);
  const errors = useSelector(state => state.courses.errors.getCourseChapter);
  const [meta, setMeta] = React.useState({});

  const chapterFetch =
    !isFetching &&
    (!errors || errors.chapterId !== chapterId) &&
    `${chapter?.id}` === `${chapterId}`;

  React.useEffect(() => {
    if (courseSlug && chapterId) {
      dispatch(CoursesActions.getCourseChapterRequest(courseSlug, chapterId));
    }
  }, [courseSlug, chapterId, dispatch]);

  if (!isFetching && errors?.status === 404) {
    return <NoMatch404 />;
  }

  if (!isFetching && errors?.status === 401 && `${errors?.chapterId}` === `${chapterId}`) {
    return <NoMatch401 />;
  }

  const getTypeIconColor = (currentChapter, terminatedChapter) => {
    if (currentChapter) {
      return muiTheme.palette.text.primary;
    } else if (terminatedChapter) {
      return green[600];
    } else grey[600];
  };

  const handleClick = () => {
    let attributes = null;
    if (chapter.asset_type === 'quiz') attributes = computeQuizMeta(chapter, meta);

    dispatch(CoursesActions.createUserChapterLinkRequest(currentUser.id, chapter, attributes));
  };

  const renderIframe = () => (
    <Grid className={classes.assetContainer}>
      <iframe src={chapter?.url_link} className={classes.iframe} allow="fullscreen" />
    </Grid>
  );

  const renderVideo = () => (
    <Grid className={classes.assetContainer}>
      <ReactHlsPlayer
        src={chapter?.url_link}
        autoPlay={false}
        controls={true}
        width="100%"
        height="auto"
      />
    </Grid>
  );

  const renderImage = () => (
    <Grid className={classes.assetContainer}>
      <img src={chapter?.url_link} alt={chapter?.title} style={{ width: '100%' }} />
    </Grid>
  );

  const renderAudio = () => (
    <Grid className={classes.assetContainer}>
      <ReactHlsPlayer
        src={chapter?.url_link}
        autoPlay={false}
        controls={true}
        width="100%"
        height="56px"
      />
    </Grid>
  );

  const renderText = () => (
    <Grid className={classes.assetContainer}>
      {chapter?.attachments?.map(attachment => (
        <a
          key={attachment.id}
          href={attachment.download_url}
          target="_blank"
          without="true"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Card className={classes.attachmentCard}>
            <DownloadForOfflineIcon className={classes.download} />
            <Typography variant="h6" className={classes.download}>
              {t(`Télécharger ${attachment.filename}`)}
            </Typography>
          </Card>
        </a>
      ))}
    </Grid>
  );

  const StepIcon = chap => {
    const isCurrentChapter = chap?.id === chapter?.id;
    return (
      <Grid>
        {getAssetTypeIcon(chap?.asset_type, getTypeIconColor(isCurrentChapter, chap?.terminated))}
      </Grid>
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {chapterFetch && (
        <Grid container className={classes.layout}>
          {/* LEFT SECTION */}
          <Grid item md={8} xs={12} className={classes.sideContainer}>
            <Card className={classes.card}>
              <a href={`/formations/${courseSlug}`} className={classes.link}>
                <ArrowLeftIcon />
                {t('APP.COURSES.SHOW.BACK_COURSE')}
              </a>
              <Typography variant="h3" component="h1">
                {chapter?.title}
              </Typography>
              {chapter?.asset_type === 'iframe' && isClientSide() && renderIframe()}
              {chapter?.asset_type === 'video' &&
                isClientSide() &&
                chapter?.url_link &&
                renderVideo()}
              {chapter?.asset_type === 'image' && isClientSide() && renderImage()}
              {chapter?.asset_type === 'audio' &&
                isClientSide() &&
                chapter?.url_link &&
                renderAudio()}
              {chapter?.asset_type === 'text' && chapter?.attachments.length > 0 && renderText()}
              {chapter?.asset_type !== 'quiz' && chapter?.content && chapter?.content !== '' && (
                <Typography
                  dangerouslySetInnerHTML={{ __html: chapter?.content }}
                  className={classes.textContent}
                />
              )}
              {chapter?.asset_type === 'quiz' && (
                <ChapterQuiz
                  meta={meta}
                  setMeta={setMeta}
                  quizSlug={chapter?.content}
                  terminated={chapter?.terminated}
                />
              )}
            </Card>
            <Grid align="center" className={classes.btnContainer}>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => handleClick()}
                disabled={!isValidQuizMeta(chapter, meta)}
              >
                {t('NEXT')}
              </Button>
            </Grid>
          </Grid>
          {/* RIGHT SECTION */}
          <Grid item md={4} xs={12} className={classes.sideContainer}>
            <Hidden smUp>
              <Stepper
                alternativeLabel
                activeStep={chapter.id}
                style={{ backgroundColor: 'transparent', padding: 0 }}
              >
                {chapter?.unit?.chapters?.map(chap => {
                  return (
                    <Step key={chap.id}>
                      <StepLabel StepIconComponent={() => StepIcon(chap)} />
                    </Step>
                  );
                })}
              </Stepper>
            </Hidden>
            <Hidden only={['xs']}>
              <Card>
                <Typography variant="h4" component="h2" className={classes.unitTitle}>
                  {`${chapter?.unit.sort_index + 1}. ${chapter?.unit.title}`}
                </Typography>
                <Divider />
                {chapter?.unit?.chapters?.map(chap => {
                  const isCurrentChapter = chap?.id === chapter?.id;
                  return (
                    <Grid key={chap?.id}>
                      <List>
                        <ListItem
                          dense
                          component={chap?.terminated ? 'a' : 'div'}
                          href={`/formations/${courseSlug}/chapitres/${chap?.id}`}
                        >
                          <ListItemIcon style={{ minWidth: 40 }}>
                            {getAssetTypeIcon(
                              chap?.asset_type,
                              getTypeIconColor(isCurrentChapter, chap?.terminated),
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={chap?.title}
                            className={isCurrentChapter && classes.listItemText}
                          />
                        </ListItem>
                      </List>
                      <Divider />
                    </Grid>
                  );
                })}
              </Card>
            </Hidden>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ShowChapter;
