import React from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { checkPostCodeIsValid } from '../../../Services/DataHelper';

type Props = {
  quizSlug: String,
  terminated: Boolean,
  meta: Object,
  setMeta: Object,
};

const useStyles = makeStyles(theme => ({
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  formLabel: {
    paddingTop: theme.spacing(1),
    lineHeight: 1.2,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
  },
  group: {
    marginTop: theme.spacing(0.5),
  },
}));

function ChapterQuiz({ terminated, quizSlug, meta, setMeta }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [postcodeError, setPostcodeError] = React.useState(null);

  const renderRadioGroup = (title, ariaLabel, radioValue, onRadioChange, group) => (
    <FormControl
      component="fieldset"
      className={classes.formControl}
      fullWidth
      required={quizSlug === 'sla-start'}
      disabled={terminated}
    >
      <FormLabel component="legend" className={classes.formLabel}>
        {title}
      </FormLabel>
      <RadioGroup
        aria-label={ariaLabel}
        name={ariaLabel}
        className={classes.group}
        value={radioValue || ''}
        onChange={onRadioChange}
        row
      >
        {group.map(radio => (
          <FormControlLabel
            key={radio}
            value={radio}
            control={<Radio color="primary" size="small" />}
            label={radio}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  const renderTextField = (title, ariaLabel, textValue, onTextChange, endText, width, number) => (
    <FormControl
      component="fieldset"
      className={classes.formControl}
      fullWidth
      required={quizSlug === 'sla-start'}
      disabled={terminated}
    >
      <FormLabel component="legend" className={classes.formLabel}>
        {title}
      </FormLabel>
      <OutlinedInput
        className={classes.input}
        id={ariaLabel}
        value={textValue || ''}
        onChange={onTextChange}
        aria-describedby={ariaLabel}
        inputProps={{
          'aria-label': { ariaLabel },
          min: 1,
        }}
        placeholder="Écrivez ici..."
        endAdornment={endText && <InputAdornment position="end">{endText}</InputAdornment>}
        style={{ width: width || 'auto' }}
        type={number ? 'number' : 'text'}
      />
    </FormControl>
  );

  if (quizSlug === 'sla-start')
    return (
      <Grid className={classes.formContainer}>
        {terminated && (
          <Typography variant="h6" style={{ marginTop: -8, marginBottom: 8 }}>
            Vous avez déjà rempli ce questionnaire, merci ! Vous pouvez passer directement au
            chapitre suivant en cliquant sur le bouton en bas de cette page.
          </Typography>
        )}
        {renderRadioGroup(
          'Civilité',
          'civility',
          meta.civility,
          event => setMeta({ ...meta, civility: event.target.value }),
          ['Madame', 'Monsieur', 'Autre'],
        )}
        {renderTextField(
          'Âge',
          'age',
          meta.age,
          event => setMeta({ ...meta, age: Math.abs(event.target.value) }),
          'ans',
          '50%',
          true,
        )}
        <FormControl
          component="fieldset"
          className={classes.formControl}
          fullWidth
          required={quizSlug === 'sla-start'}
          disabled={terminated}
        >
          <FormLabel component="legend" className={classes.formLabel}>
            Votre code postal
          </FormLabel>
          <OutlinedInput
            className={classes.input}
            id="postCode"
            value={meta.postcode}
            onChange={event => setMeta({ ...meta, postcode: event.target.value })}
            aria-describedby="postCode"
            inputProps={{
              'aria-label': 'postCode',
            }}
            placeholder="Écrivez ici..."
            style={{ width: '50%' }}
            type="tel"
            onBlur={() => setPostcodeError(checkPostCodeIsValid(meta.postcode))}
            helperText={t(postcodeError)}
            error={postcodeError !== null}
          />
          {!!postcodeError && (
            <FormHelperText error id="postcodeError">
              {t(postcodeError)}
            </FormHelperText>
          )}
        </FormControl>
        {renderRadioGroup(
          'Êtes-vous aidant ?',
          'primary',
          meta.primary,
          event => setMeta({ ...meta, primary: event.target.value }),
          ['Oui', 'Non', 'Ne sais pas'],
        )}
        {['Non', 'Ne sais pas'].includes(meta.primary) &&
          renderRadioGroup(
            'Vous êtes ?',
            'who',
            meta.who,
            event => setMeta({ ...meta, who: event.target.value }),
            [
              'Un particulier',
              'Un professionnel de PFR ',
              'Autre professionnel',
              'Professionnel concerné par la thématique',
            ],
          )}
        {meta.primary === 'Oui' &&
          renderRadioGroup(
            'Quel est votre lien avec votre proche ?',
            'link',
            meta.link,
            event => setMeta({ ...meta, link: event.target.value }),
            ['Familial', 'Amical', 'Connaissance'],
          )}
        {meta.primary === 'Oui' &&
          renderRadioGroup(
            'Pathologie de votre proche :',
            'pathology',
            meta.pathology,
            event => setMeta({ ...meta, pathology: event.target.value }),
            ['Maladie neuro-dégénérative', 'Handicap', 'Maladie chronique', 'Aucune'],
          )}
        {meta.primary === 'Oui' &&
          renderRadioGroup(
            'Quelle est votre situation professionnelle ?',
            'profession',
            meta.profession,
            event => setMeta({ ...meta, profession: event.target.value }),
            ['Actif', 'Retraité', 'Sans activité', 'Scolarisé'],
          )}
        {meta.primary === 'Oui' &&
          renderRadioGroup(
            'Avez-vous déjà été accompagné par une plateforme de répit ?',
            'pfr_support',
            meta.pfr_support,
            event => setMeta({ ...meta, pfr_support: event.target.value }),
            ['Oui', 'Non'],
          )}
        {renderRadioGroup(
          'Comment évaluez-vous votre niveau de connaissance sur la thématique de la formation ?',
          'knowledge_level',
          meta.knowledge_level,
          event => setMeta({ ...meta, knowledge_level: event.target.value }),
          [
            'Aucune connaissance',
            'Quelques connaissances',
            'Bonnes connaissances',
            'Très bonnes connaissances',
          ],
        )}
        {renderTextField(
          'Comment avez-vous eu connaissance de cette formation ?',
          'course_hearing',
          meta.course_hearing,
          event => setMeta({ ...meta, course_hearing: event.target.value }),
          null,
        )}
      </Grid>
    );

  if (quizSlug === 'sla-end')
    return (
      <Grid className={classes.formContainer}>
        {terminated && (
          <Typography variant="h6" style={{ marginTop: -8, marginBottom: 8 }}>
            Vous avez déjà rempli ce questionnaire, merci ! Vous pouvez terminer la formation en
            cliquant sur le bouton en bas de cette page.
          </Typography>
        )}
        {!terminated && (
          <Typography variant="h6" style={{ marginTop: -8, marginBottom: 8 }}>
            Complétez les affirmations suivantes :
          </Typography>
        )}
        {renderRadioGroup(
          'Cette formation a répondu à vos attentes :',
          'expectations',
          meta.expectations,
          event => setMeta({ ...meta, expectations: event.target.value }),
          ['Pas du tout d’accord', 'Pas d’accord', 'D’accord', 'Tout à fait d’accord'],
        )}
        {renderRadioGroup(
          'La navigation dans la formation vous a semblée simple :',
          'navigation',
          meta.navigation,
          event => setMeta({ ...meta, navigation: event.target.value }),
          ['Pas du tout d’accord', 'Pas d’accord', 'D’accord', 'Tout à fait d’accord'],
        )}
        {renderRadioGroup(
          'Vous avez facilement trouvé la formation sur le site internet :',
          'location',
          meta.location,
          event => setMeta({ ...meta, location: event.target.value }),
          ['Pas du tout d’accord', 'Pas d’accord', 'D’accord', 'Tout à fait d’accord'],
        )}
        {renderRadioGroup(
          'Vous aviez déjà connaissance des informations présentées :',
          'knowledge',
          meta.knowledge,
          event => setMeta({ ...meta, knowledge: event.target.value }),
          ['Pas du tout d’accord', 'Pas d’accord', 'D’accord', 'Tout à fait d’accord'],
        )}
        {renderRadioGroup(
          'Les formats présentés étaient adaptés :',
          'adapted_formats',
          meta.adapted_formats,
          event => setMeta({ ...meta, adapted_formats: event.target.value }),
          ['Pas du tout d’accord', 'Pas d’accord', 'D’accord', 'Tout à fait d’accord'],
        )}
        {renderRadioGroup(
          'Vous pensez réutiliser les outils/astuces/techniques présentés :',
          'tools',
          meta.tools,
          event => setMeta({ ...meta, tools: event.target.value }),
          ['Oui', 'Non'],
        )}
        {renderRadioGroup(
          'La durée de la formation était adaptée :',
          'duration',
          meta.duration,
          event => setMeta({ ...meta, duration: event.target.value }),
          ['Oui', 'Non'],
        )}
        {renderTextField(
          'Auriez-vous souhaité aborder d’autres sujets ? Si oui lesquels ?',
          'remarks',
          meta.remarks,
          event => setMeta({ ...meta, remarks: event.target.value }),
          null,
        )}
      </Grid>
    );

  return <></>;
}

export default ChapterQuiz;
